import React, { useState } from 'react';
import { Button } from '../button/Button';
import { navbar } from 'texts/texts';
import './Navbar.css';
import { Link } from "react-router-dom";

interface NavbarProps { }

const hamburgerIconSrc = `${process.env.PUBLIC_URL}/assets/hamburger-icon.png`;
const logoImageSrc = `${process.env.PUBLIC_URL}/assets/toastie-logo.png`;

export const Navbar: React.FC<NavbarProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleWaitlistClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdxIO7hlJK8Ew9Y1ykensm5cg3-vy1ddFKLNjKkFFo9Ymt-Rg/viewform', '_blank');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className='hamburger-logo-container'>
        <button className="hamburger" onClick={toggleMenu}>
          <img id='hamburger-icon' src={hamburgerIconSrc} alt="Hamburger Icon" />
        </button>
        <div className="logo">
          <img src={logoImageSrc} alt="Toastie Logo" />
          <Link to="/">
            <span className="logo-text">toastie</span>
          </Link>
        </div>
      </div>
      <div className='links-button-container'>
        <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <Link to="/contact-us">{navbar.nav.contact}</Link>
          <Link to="/our-story">{navbar.nav.story}</Link>
          <Link to="/blog">{navbar.nav.blog}</Link>
        </div>
        <Button label={navbar.buttonText} onClick={handleWaitlistClick} className="navbar-waitlist-button" />
      </div>
    </nav>
  );
};